import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {

    loginSubject = new Subject<any>();

    storageSubject = new Subject<any>();
    navbarSubject = new Subject<any>();

    constructor() { }

    getValue(key: string): string {
        let data = localStorage.getItem(key)
        return data ? data : "";
    }

    setValue(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    removeValue(key: string): void {
        localStorage.removeItem(key);
    }

    getGlobalSearch() {
        let searchData = localStorage.getItem(StorageKey.globalSearch);
        if (searchData) {
            return JSON.parse(searchData)
        }
        return Object.assign({});
    }

    setGlobalSearch(key: string, column: string, value: string): void {
        var model = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : Object.assign({});
        model[column] = value;
        localStorage.setItem(key, JSON.stringify(model));
    }
}

export class StorageKey {
    public static currentUser = 'currentUser';
    public static authToken = 'authToken';
    public static rememberMe = 'rememberMe';
    public static currentLanguage = 'currentLanguage';
    public static globalSearch = 'globalSearch';
}
