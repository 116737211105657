import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UserInfoService } from 'src/app/services/user-info.service';
import { StorageKey, StorageService } from 'src/app/services/common/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from 'src/app/services/common/alert.service';
import { ResourceService } from 'src/app/services/common/resource.service';
import { UtilityService } from 'src/app/services/utility.service';
import { IndexedDBService } from 'src/app/services/common/indexedDb.service';

@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class TopHeaderComponent implements OnInit {

    location: any;
    containerClass: any;

    isLoggedIn: any = false;
    loginUserName: any = "";
    classCountryApplied = false;
    classApplied = false;
    countryList: any[];
    public lat;
    public lng;
    resource: any = {};
    selectedCountry: any = '';
    selectedLanguage: any = "english";
    changeCountry: boolean = false;

    languages: any[] =
        [
            { text: "EN", value: "english", src: "assets/img/flag/us.jpg" },
            { text: "FR", value: "french", src: "assets/img/flag/france.jpg" },
            // { text: "Ger", value: "germany", src: "assets/img/flag/germany.jpg" },
            // { text: "Rus", value: "russian", src: "assets/img/flag/russia.jpg" },
            // { text: "Ita", value: "italian", src: "assets/img/flag/italy.jpg" },
        ];

    constructor(
        private router: Router,
        private utilityService: UtilityService,
        private userInfoService: UserInfoService,
        private storageService: StorageService,
        private resourceService: ResourceService,
        private alertService: AlertService,
        private indexedDbService: IndexedDBService
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                    if (this.location == '/index-3' || this.location == '/locations' || this.location == '/blog-details' || this.location == '/checkout') {
                        this.containerClass = 'container';
                    } else {
                        this.containerClass = 'container-fluid';
                    }
                }
            });
    }

    async ngOnInit() {
        this.getLocation();
        this.loadResoruce();

        this.storageService.storageSubject.subscribe(response => {
            if (response?.countryId) {
                this.selectedCountry = response?.countryId ? response?.countryId : '';
                let country = this.countryList.find(x => x.id == this.selectedCountry);
                if (country)
                    this.storageService.setGlobalSearch(StorageKey.globalSearch, "countryId", country.id);

                this.storageService.setGlobalSearch(StorageKey.globalSearch, "stateId", response?.stateId ? response?.stateId : '');
                this.storageService.setGlobalSearch(StorageKey.globalSearch, "cityId", response?.cityId ? response?.cityId : '');
            }
        });

        this.resourceService.languageSubject.subscribe(response => {
            this.resource = response;
        });
        await this.getCountryList();
        const accessTokenObj = this.storageService.getValue(StorageKey.authToken);
        // Retrieve rememberMe value from local storage
        const rememberMe = this.storageService.getValue(StorageKey.rememberMe);
        if (accessTokenObj && rememberMe == 'yes') {
            let userData = this.storageService.getValue(StorageKey.currentUser);
            this.userInfoService.setUserInfo(JSON.parse(userData));
            this.userInfoService.setLoginFlag(true);
            this.userInfoService.setToken(accessTokenObj);
            // this.router.navigate(['/index']);
        }

        this.isLoggedIn = this.userInfoService.getLoginFlag();

        this.storageService.loginSubject.subscribe(message => {
            this.isLoggedIn = this.userInfoService.getLoginFlag();

            let userInfo = this.userInfoService.getUserInfo();
            if (userInfo)
                this.loginUserName = userInfo?.firstName + ' ' + userInfo?.lastName;
        });
        let userInfo = this.userInfoService.getUserInfo();
        if (userInfo)
            this.loginUserName = userInfo?.firstName + ' ' + userInfo?.lastName;

        //disabled location <<
        // >

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position) {
                        this.lat = position.coords.latitude;
                        this.lng = position.coords.longitude;

                        var modelLocation = {
                            lat: this.lat,
                            long: this.lng,
                        }

                        this.utilityService.getCurrentLocation(modelLocation).subscribe(
                            response => {
                                if (response.success) {
                                    this.selectedCountry = response.data?.countryId ? response.data?.countryId : '';
                                    this.storageService.setGlobalSearch(StorageKey.globalSearch, "countryId", response.data?.countryId ? response.data?.countryId : '');
                                    this.storageService.setGlobalSearch(StorageKey.globalSearch, "stateId", response.data?.stateId ? response.data?.stateId : '');
                                    this.storageService.setGlobalSearch(StorageKey.globalSearch, "cityId", response.data?._id ? response.data?._id : '');
                                }
                                this.storageService.storageSubject.next(this.storageService.getGlobalSearch());
                            });
                    }
                },
                () => {
                    let searchData = this.storageService.getGlobalSearch();
                    if (!searchData.countryId) {
                        let country = this.countryList.find(x => x.name == "Canada");
                        if (country) {
                            this.selectedCountry = country.id;
                            this.storageService.setGlobalSearch(StorageKey.globalSearch, "countryId", country.id);
                            this.storageService.setGlobalSearch(StorageKey.globalSearch, "stateId", '');
                            this.storageService.setGlobalSearch(StorageKey.globalSearch, "cityId", '');
                            this.storageService.storageSubject.next(this.storageService.getGlobalSearch());
                        }
                    }
                    else {
                        this.selectedCountry = searchData.countryId;
                    }
                }
            );
        }
    }

    loadResoruce() {
        this.resourceService.loadResoruce().subscribe(
            response => {
                this.resource = response;
            });
    }

    async getCountryList() {
        this.countryList = await this.indexedDbService.getCountries();
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                }
            });
        } else {
            this.alertService.showInfo("Geolocation is not supported by this browser");
        }
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    toggleCountryClass() {
        this.classCountryApplied = !this.classCountryApplied;
    }

    onChangeLanguage(row: any) {
        this.storageService.setValue("currentLanguage", this.selectedLanguage)

        this.resourceService.loadResoruce().subscribe(
            response => {
                this.resourceService.languageSubject.next(response);
            });
    }

    onChangeCountry() {
        let country = this.countryList.find(x => x.id == this.selectedCountry);
        if (country)
            this.storageService.setGlobalSearch(StorageKey.globalSearch, "countryId", country.id);
        this.storageService.setGlobalSearch(StorageKey.globalSearch, "stateId", '');
        this.storageService.setGlobalSearch(StorageKey.globalSearch, "cityId", '');
        this.storageService.storageSubject.next(this.storageService.getGlobalSearch());
        this.changeCountry = true
    }

    logout() {
        this.userInfoService.setToken();
        this.storageService.removeValue(StorageKey.authToken);
        this.storageService.removeValue(StorageKey.currentUser);
        this.storageService.removeValue(StorageKey.rememberMe);
        //sessionStorage.setItem('currentUser', JSON.stringify({}));
        sessionStorage.removeItem('currentUser');
        this.userInfoService.setLoginFlag(false);
        this.storageService.loginSubject.next(false);
        //this.cookieService.delete('currentUser');
        this.router.navigate(['/']);
    }
}